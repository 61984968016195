<script>
export default {
  props: ["data"],
  data: () => ({
    show: false,
    valid: true,
    trechos: [],
    clientes: [],
    motoristas: [],
    agencias: [],
    fieldRules: [(v) => !!v || "Este campo é requerido"],
  }),
  mounted() {
    this.loadAgencies();
    this.loadClients();
    this.loadTrechos();
    this.loadMotoristas();
  },
  methods: {
    async setDriverByDelivery() {
      try {
        const response = await this.$http.post(
          `encomendas/entrega-motorista`,
          {
            id_motorista: this.data.id_motorista,
            id_encomenda: this.data.id,
            id_operador: this.data.id_operador,
            id_agencia: this.data.id_agencia,
          }
        );
        this.$store.state.showModalDriverReleases = false
        this.$store.dispatch("showMessage", {
          show: true,
          color: `${response.data.success ? "success" : "error"} darken-1`,
          text: response.data.success
            ? response.data.success
            : response.data.error,
        });
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          color: `${"error"} darken-1`,
          text: error,
        });
      }
    },
    loadAgencies() {
      this.$http.get("/agencias").then((r) => {
        this.agencias = r.data;
      });
    },
    loadMotoristas() {
      this.$http
        .get(`/motoristas/ativos`)
        .then((r) => {
          this.motoristas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadClients() {
      this.$http
        .get(`/encomendas/clientes`)
        .then((r) => {
          this.clientes = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadTrechos() {
      this.$http
        .get(`/localidades`)
        .then((r) => {
          this.trechos = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        console.log("");
      } else {
        this.$store.dispatch("showMessage", {
          show: true,
          color: "warning darken-1",
          text: "Atencão um ou mais campos precisam ser preenchidos!",
        });
      }
    },
  },
};
</script>
