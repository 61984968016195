<template>
  <v-container class="mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon>
      {{ "Lançamentos" }}
    </v-subheader>
    <v-card flat>
      <v-card-title>
        <v-row dense class="text-center">
          <v-col cols="12" sm="12" md="2">
            <DatePicker
              :label="'Data Inicial'"
              @dateValue="filter.dateStart = $event"
            />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <DatePicker
              :label="'Data Final'"
              @dateValue="filter.dateEnd = $event"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Pesquisa rápida pelos dados filtrados"
              single-line
              hide-details
              flat
              :disabled="items.length == 0 ? true : false"
            ></v-text-field>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="12" md="2">
            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="() => searchData()"
                  color="green"
                  depressed
                  dark
                  v-on="on"
                  class="mb-2 mt-3"
                >
                  <v-icon>mdi-magnify</v-icon> BUSCAR
                </v-btn>
              </template>
              <span>Buscar pelos termos do filtro</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-0"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom color="black">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="() => getIdPackpage(item.id_encomenda)"
                :disabled="
                  item.cancelada == 0 && item.status != 'ENTREGUE'
                    ? false
                    : true
                "
                small
                v-on="on"
                depressed
                block
                dark
                color="yellow darken-2"
                >Lançar mot. manualmente</v-btn
              >
            </template>
            <span
              >Lançar manualmente o motorista no caso de o mesmo ter mudado na
              entrega da encomenda!</span
            >
          </v-tooltip>
        </template>
        <template v-slot:item.cobranca="{ item }">
          {{
            item.cobranca == 1
              ? "PAGO"
              : item.cobranca == 2
              ? "À PAGAR"
              : "CONTRATO"
          }}
        </template>
        <template v-slot:item.cancelada="{ item }">
          {{ item.cancelada == 1 ? "SIM" : "NÃO" }}
        </template>
        <template v-slot:item.cod_barras="{ item }">
          <MovimentacaoEncomendas :codBarras="item.cod_barras" />
        </template>
        <template v-slot:item.a_pagar="{ item }">
          {{ item.a_pagar == 1 ? "Sim" : "Não" }}
        </template>
      </v-data-table>
    </v-card>
    <ReleaseByDrivers :data="encomenda" />
  </v-container>
</template>

<script>
import DatePicker from "@/components/Calendars.vue";
import ReleaseByDrivers from "./components/Lancamentos/index.vue";
import MovimentacaoEncomendas from "../../../components/MovimentacaoEncomendas.vue";
export default {
  components: {
    DatePicker,
    ReleaseByDrivers,
    MovimentacaoEncomendas,
  },
  data: () => ({
    isShow: false,
    encomenda: {},
    dialog: false,
    modalShow: true,
    search: "",
    filter: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    dadosRecuperados: [],
    headers: [
      { text: "Cód. Barras", value: "cod_barras", sortable: false },
      { text: "Motorista", value: "motorista", sortable: false },
      { text: "Ag.Origem", value: "origem", sortable: false },
      { text: "Ag.Destino", value: "destino", sortable: false },
      { text: "Remetente", value: "remetente", sortable: false },
      { text: "Destinatario", value: "destinatario", sortable: false },
      { text: "A Pagar?", value: "a_pagar", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    items: [],
    id_encomenda: 0,
  }),
  created() {
    this.initialize();
  },
  methods: {
    showModal() {
      return !this.modalShow;
    },
    getIdPackpage(id_encomenda) {
      this.$store.state.showModalDriverReleases = true;
      this.$http.get(`/encomendas/${id_encomenda}`).then((r) => {
        this.encomenda = r.data[0];
      });
    },
    initialize() {
      this.$store.state.showModalDriverReleases = false;
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/encomendas/por-agencia-admin`, this.filter)
        .then((r) => {
          this.items = r.data;
          this.$store.dispatch("activeLoader", false);
        });
    },
    searchData() {
      this.$store.state.showModalDriverReleases = false;
      this.$store.dispatch("activeLoader", true);
      this.items = [];
      this.$http
        .post(`/encomendas/por-agencia-admin`, this.filter)
        .then((r) => {
          this.items = r.data;
          this.$store.dispatch("activeLoader", false);
        });
    },
  },
};
</script>
